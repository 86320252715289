var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-page"},[_c('ForgotPassModal',{attrs:{"visible":_vm.isForgotModalVisible},on:{"close-modal":() => _vm.isForgotModalVisible = false}}),_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"right-side"},[_vm._m(1),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('span',{staticClass:"item-label"},[_vm._v("Username")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              { rules: [{ required: true, message: 'Please input your Username!' }] },
            ]),expression:"[\n              'email',\n              { rules: [{ required: true, message: 'Please input your Username!' }] },\n            ]"}]},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('span',{staticClass:"item-label"},[_vm._v("Password")]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              { rules: [{ required: true, message: 'Please input your Password!' }] },
            ]),expression:"[\n              'password',\n              { rules: [{ required: true, message: 'Please input your Password!' }] },\n            ]"}],attrs:{"type":"password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),(_vm.needGoogleAuth)?_c('a-form-item',[_c('span',{staticClass:"item-label"},[_vm._v("Authentication Code")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'googleAuthCode',
              { rules: [{ required: true, message: 'Please enter your Authentication Code!' }] },
            ]),expression:"[\n              'googleAuthCode',\n              { rules: [{ required: true, message: 'Please enter your Authentication Code!' }] },\n            ]"}],attrs:{"type":"number"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"qrcode"},slot:"prefix"})],1)],1):_vm._e(),_c('a-form-item',[_c('div',{staticClass:"login-footer"},[_c('span',{staticClass:"login-form-forgot",on:{"click":() => _vm.isForgotModalVisible = true}},[_vm._v(" Forgot password? ")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Log in ")])],1)])],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-side"},[_c('img',{attrs:{"src":require("../assets/loginPage/SB.svg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/loginPage/smartBet.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('span',[_vm._v("Welcome Back")]),_c('span',[_vm._v("Please enter your details to continue to Back office.")])])
}]

export { render, staticRenderFns }